<template>
  <div class="about_wrapper">
    <div class="about">
      <div class="row pd20" v-for="item in aboutList" :key="item.type">
        <h5 class="">{{ item.type }}</h5>
        <div class="about_content" v-html="item.text"></div>
      </div>
    </div>

    <!-- 留言 -->
    <div class="comment mt20 pd20">
      <div id="SOHUCS" sid="-1"></div>
    </div>
  </div>
</template>

<script>
import about from "@/api/about";
import  init  from "@/utils/index";
import  initData  from "@/utils/comment";
export default {
  data() {
    return {
      aboutList: [],
    };
  },
  created() {
    this.getAbout();
  },
  mounted() {
    init();
    initData();
  },
  destroyed(){
    window.changyan=undefined;
    window.cyan=undefined; 
  },
  methods: {
    async getAbout() {
      const res = await about.getAbout();
      this.aboutList = res.data;
    },
  },
};
</script>

<style lang="less">
.about_wrapper {
  width: 100%;
  .about {
    border-radius: 5px;
    background-color: #fff;
    .row {
      h5 {
        height: 50px;
        line-height: 50px;
        font-weight: bold;
        font-size: 18px;
        margin: 0;
        border-bottom: 1px solid #b9b9b9;
      }
      .about_content {
        padding: 20px 0 0;
      }
    }
  }

  .message {
    border-radius: 5px;
    background-color: #fff;
  }
}
</style>